import React from "react";
import Styles from "./Footer.module.css";
import { Box, Text, Image, Flex } from "@chakra-ui/react";
import Logo from "../../assets/images/logo.svg";
import ContactItem from "./Components/ContactItem";
import { useTranslation } from "react-i18next";
import SocialIcons from "../SocialIcons";
import { Link } from "react-router-dom";
import { IoMailOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { SlLocationPin } from "react-icons/sl";
import { t } from "i18next";
import { useGetWebsiteContactsDataQuery } from "../../store/APIs/websiteDataApis"

const InlineContactItems = () => {
  const { data } = useGetWebsiteContactsDataQuery();

  if (!data) return null;

  return (
    <>
      <ContactItem
        dir="row"
        justify="space-evenly"
        align="center"
        icon={FiPhoneCall}
        text={data.phone}
        type="phone"
      />
      <ContactItem
        dir="row"
        justify="space-evenly"
        align="center"
        icon={IoMailOutline}
        type="mail"
        text={data.email}
      />
      <ContactItem
        type="location"
        dir="row"
        justify="space-evenly"
        align="center"
        icon={SlLocationPin}
        text={t("address")}
      />
    </>
  );
};

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      className={Styles.footer}
    >
      <Box w="100%" className={Styles.footerContainer}>
        <Flex
          direction="column"
          align={{
            base: "center",
            sm: "flex-start",
            md: "flex-start",
            xl: "flex-start",
          }}
          m={{
            base: 3,
            md: 0,
            xl: 0,
          }}
        >
          <Image
            mb={4}
            src={Logo}
            alt="Arcade Logo"
            w={{ base: "35%", md: "35", xl: "80px" }}
          />
          <InlineContactItems />
        </Flex>

        <Box>
          <Flex
            direction="column"
            align={{
              base: "center",
              md: "flex-start",
              xl: "flex-start",
            }}
            m={{
              base: 3,
              md: 5,
              xl: 5,
            }}
          >
            <Text fontFamily="Heading" as="b">
              <Link to={"/"}>{t("home")}</Link>
            </Text>
            <Link to={"/courses"}>{t("courses")}</Link>
            <Link to={"/contact"}>{t("contactus")}</Link>
          </Flex>

          <SocialIcons align="flex-start" />
        </Box>
      </Box>

      <Text fontFamily="Body">
        © {new Date().getFullYear()} {t("rights")}.
      </Text>
    </Flex>
  );
};

export default Footer;
